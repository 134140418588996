import PropTypes from 'prop-types';
import ArticlePromotionDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/ArticlePromotionDataTransformerBlock';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

const ArticlePromotionModule = ({ isGrid = false, ...rest }) => {
    const [css] = useStyletron();

    return (
        <section
            className={css({
                marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)',
                padding: ['64px 8px 0px 8px', null, '12px 12px 64px 12px', null, '16px 16px 80px 16px'],
                backgroundColor: 'var(--color-bg)',
            })}
        >
            <div
                className={css({
                    maxWidth: 'var(--color-bg)',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                })}
            >
                <ArticlePromotionDataTransformerBlock {...rest} />
            </div>
        </section>
    );
};

ArticlePromotionModule.propTypes = {
    isGrid: PropTypes.bool,
};

export default ArticlePromotionModule;
